exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-access-index-jsx": () => import("./../../../src/pages/access/index.jsx" /* webpackChunkName: "component---src-pages-access-index-jsx" */),
  "component---src-pages-concept-index-jsx": () => import("./../../../src/pages/concept/index.jsx" /* webpackChunkName: "component---src-pages-concept-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-eat-index-jsx": () => import("./../../../src/pages/eat/index.jsx" /* webpackChunkName: "component---src-pages-eat-index-jsx" */),
  "component---src-pages-event-index-jsx": () => import("./../../../src/pages/event/index.jsx" /* webpackChunkName: "component---src-pages-event-index-jsx" */),
  "component---src-pages-fee-index-jsx": () => import("./../../../src/pages/fee/index.jsx" /* webpackChunkName: "component---src-pages-fee-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-greeting-index-jsx": () => import("./../../../src/pages/greeting/index.jsx" /* webpackChunkName: "component---src-pages-greeting-index-jsx" */),
  "component---src-pages-introduction-index-jsx": () => import("./../../../src/pages/introduction/index.jsx" /* webpackChunkName: "component---src-pages-introduction-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-movein-index-jsx": () => import("./../../../src/pages/movein/index.jsx" /* webpackChunkName: "component---src-pages-movein-index-jsx" */),
  "component---src-pages-solution-index-jsx": () => import("./../../../src/pages/solution/index.jsx" /* webpackChunkName: "component---src-pages-solution-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-3-service-index-jsx": () => import("./../../../src/templates/3_service/index.jsx" /* webpackChunkName: "component---src-templates-3-service-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

